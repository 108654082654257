<template>
    <div :class="asModal ? 'modal-content' : 'notificationBarTop'" v-show="asModal || showSlidedown" :aria-hidden="!(asModal || showSlidedown)" role="alert" ref="container">
        <template v-if="asModal">
            <div class="modal-header">
                <h2 class="modal-title">{{ sessionExpired ? "Session Expired" : "Session Expiring" }}</h2>
            </div>
            <div class="modal-body">
                <template v-if="!sessionExpired">
                    Your session will expire in
                    <span role="timer">{{ relativeSessionTimeoutDisplay }}</span>
                    .
                </template>
                <template v-else-if="isLoggedIn">Your login has expired. Log in again to resume your session.</template>
                <template v-else>Your session has expired. You must start over.</template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" id="refreshBtn" v-if="!sessionExpired" @click="refresh">Refresh</button>
                <button type="button" class="btn btn-primary" id="loginBtn" v-else-if="isLoggedIn" @click="login">Log In</button>
            </div>
        </template>
        <template v-else>
            <template v-if="!sessionExpired">
                Your session will expire in <span role="timer">{{ relativeSessionTimeoutDisplay }}</span
                >.
            </template>
            <template v-else-if="isLoggedIn">Your login has expired. Log in again to resume your session.</template>
            <template v-else>Your session has expired. You must start over.</template>
            <button type="button" class="btn align-baseline" id="refreshBtn" v-if="!sessionExpired" @click="refresh">Refresh</button>
            <button type="button" class="btn align-baseline" id="loginBtn" v-else-if="isLoggedIn" @click="login">Log In</button>

            <button type="button" aria-label="Close" class="close icon icon-close" @click.prevent="hideSlidedown" id="closeBtn"></button>
        </template>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, watch, onUnmounted, SetupContext, inject } from "vue";
import { asyncComputed } from "@vueuse/core";
import { useMainStore } from "@/mainStore";
import { loadUserDataInjectKey, loadUserData as loadUserDataType, getLoginUrlInjectKey, getLoginUrl as getLoginUrlType } from "./AppShell.3.vue.InjectKeys";
import { focusTrapSetup } from "@/lib/focusTrapMixin.3";
interface Props {
    expireTime: Date;
    isLoggedIn: boolean;
    asModal: boolean;
}
export default defineComponent({
    props: {
        asModal: Boolean,
        expireTime: Date,
        isLoggedIn: Boolean,
    },
    //@ts-expect-error
    setup(props: Props, context: SetupContext) {
        const containerRef = ref<HTMLElement>(null!);
        const focusTrap = focusTrapSetup(containerRef);
        const loadUserData = inject(loadUserDataInjectKey) as loadUserDataType;
        const getLoginUrl = inject(getLoginUrlInjectKey) as getLoginUrlType;

        const now = ref(Date.now());
        const nowTimerId = ref(null as null | ReturnType<typeof setInterval>);
        const showSlidedown = ref(true);

        const mainStore = useMainStore();

        nowTimerId.value = setInterval(() => {
            now.value = Date.now();
        }, 100);
        focusTrap.focusTrapSetEnabled(props.asModal);

        onUnmounted(() => {
            const nowTimerIdValue = nowTimerId.value;
            if (nowTimerIdValue != null) {
                clearInterval(nowTimerIdValue);
            }
        });

        const sessionExpired = computed(() => {
            return relativeSessionTimeout.value <= 0;
        });
        const relativeSessionTimeout = computed(() => {
            return props.expireTime.getTime() - now.value;
        });

        const loginUrl = asyncComputed(async () => {
            const url = await mainStore.getUrl("loginReturnAndClose");
            return await getLoginUrl(0, url);
        });

        const relativeSessionTimeoutDisplay = computed(() => {
            const relativeSessionTimeoutNumber = relativeSessionTimeout.value;
            if (relativeSessionTimeoutNumber < 10 * 1000) {
                return new Intl.NumberFormat([], { maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(relativeSessionTimeoutNumber / 1000) + " seconds";
            } else {
                const relativeSessionTimeoutSeconds = Math.round(relativeSessionTimeoutNumber / 1000);
                const formatList = (list: string[]) => {
                    list = list.filter(a => a != "");
                    if (list.length == 0) return "";
                    let result = list[0];
                    if (list.length == 1) return result;
                    for (let i = 1; i < list.length - 1; i++) {
                        result += ", " + list[i];
                    }
                    result += " and " + list[list.length - 1];
                    return result;
                };
                const formatUnit = (number: number, unit: string) => {
                    if (number == 0) return "";
                    return new Intl.NumberFormat([], { unit, style: "unit", unitDisplay: "long" }).format(number);
                };
                //cspell:ignore trunc
                const hours = Math.trunc(relativeSessionTimeoutSeconds / 3600);
                const minutes = Math.trunc((relativeSessionTimeoutSeconds / 60) % 60);
                const seconds = Math.trunc(relativeSessionTimeoutSeconds % 60);
                const parts = relativeSessionTimeoutSeconds >= 3600 ? [formatUnit(hours, "hour"), formatUnit(minutes, "minute")] : relativeSessionTimeoutSeconds >= 600 ? [formatUnit(minutes, "minute")] : [formatUnit(minutes, "minute"), formatUnit(seconds, "second")];
                return formatList(parts);
            }
        });

        watch(
            () => sessionExpired.value,
            newVal => {
                if (newVal && !props.isLoggedIn) {
                    context.emit("close");
                }
            },
        );

        const login = async () => {
            const loginWindow = window.open(loginUrl.value)!;
            await waitForWindow(loginWindow);
            await loadUserData();
            if (await mainStore.isLoggedInPromise) {
                if (props.asModal) {
                    context.emit("close");
                } else {
                    showSlidedown.value = true;
                }
            } else {
                debugger;
            }
        };

        const waitForWindow = (winObj: Window) => {
            return new Promise((resolve, reject) => {
                const f = () => {
                    try {
                        if (winObj.closed) {
                            resolve(null);
                        } else {
                            setTimeout(f, 100);
                        }
                    } catch (e) {
                        reject(e);
                    }
                };
                f();
            });
        };

        const refresh = async () => {
            const refreshUrl = await mainStore.getUrl("extendSession");
            await fetch(refreshUrl, { headers: { "X-Session-Refresh": "force" } });
            if (props.asModal) {
                context.emit("close");
            } else {
                showSlidedown.value = true;
            }
        };

        const hideSlidedown = () => {
            showSlidedown.value = false;
        };

        return {
            // Refs
            container: containerRef,

            // State
            now,
            nowTimerId,
            showSlidedown,

            //Computed
            sessionExpired,
            relativeSessionTimeout,
            relativeSessionTimeoutDisplay,
            loginUrl,

            //Methods
            login,
            refresh,
            hideSlidedown,
        };
    },
});
</script>

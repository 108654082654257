<style scoped>
.help-button {
    -webkit-appearance: none;
    background: none;
    border: none;
}
::v-deep(.popper) {
    white-space: pre-wrap;
}
.border-0 {
    border: 0 !important;
}
.m-0 {
    margin: 0 !important;
}
</style>
<style>
:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-text-color: inherit;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 16px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
<template>
    <Popper ref="popperRef" :show="showPopper" arrow :placement="placement || 'top'" v-if="text != null && !/^\s*$/.test(text)" class="m-0 border-0">
        <button type="button" @click.stop="triggerClick" @keydown.enter.prevent="triggerClick" @keydown.space.prevent="triggerClick" @mouseover.prevent="handleHoverEvent(true)" @mouseleave.prevent="handleHoverEvent(false)" @focus="showPopper = true" @blur="showPopper = false" slot="reference" :aria-label="labelText + 'More information'" class="help-button">
            <span class="icon" :class="iconClass || 'icon-info'" aria-hidden="true"></span>
        </button>
        <template #content>
            <div :id="cid?.toString()" role="tooltip">{{ text }}</div>
        </template>
    </Popper>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch, getCurrentInstance, computed } from "vue";
import Popper from "vue3-popper";
import "vue-popperjs/dist/vue-popper.css";
type Props = {
    text: string;
    placement: string;
    hover: boolean;
    iconClass: string;
    label: string;
};
export default defineComponent({
    components: {
        Popper,
    },
    props: ["text", "placement", "hover", "iconClass", "label"],
    setup(props: Props) {
        const cid = getCurrentInstance()?.uid;
        const popperRef = ref<typeof Popper>(null!);
        const showPopper = ref<boolean>(false);

        const labelText = computed(() => {
            return props.label ? props.label + ": " : "";
        });
        const hover = computed(() => props.hover ?? true);

        const triggerClick = () => {
            showPopper.value = !showPopper.value;
        };
        const handleHoverEvent = (state: boolean) => {
            if (hover.value) {
                showPopper.value = state;
            }
        };

        onMounted(() => {
            if (popperRef.value) {
                watch(
                    () => popperRef.value?.show,
                    state => {
                        popperRef.value?.$refs.triggerNode.querySelector("button").setAttribute("aria-expanded", !!state);
                        popperRef.value?.$refs.triggerNode.querySelector("button").setAttribute("aria-describedby", state ? cid : "");
                        popperRef.value?.$refs.popperNode.setAttribute("aria-hidden", !state);
                    },
                    { immediate: true },
                );
            }
        });
        return {
            cid,
            popperRef,
            showPopper,
            labelText,
            triggerClick,
            handleHoverEvent,
        };
    },
});
</script>

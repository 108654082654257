import { convertUtcToTimeZone } from "./timezoneFunctions";
import { useMainStore } from "@/mainStore";
export function localeDateTime(value: string | null | undefined | Date) {
    return value == null ? value : new Date(value).toLocaleString();
}
export function localeDate(value: string | null | undefined | Date) {
    return value == null ? value : new Date(value).toLocaleDateString();
}
export function localeTime(value: string | null | undefined | Date) {
    return value == null ? value : new Date(value).toLocaleTimeString();
}
export function tenantDateTime(value: string | null | undefined | Date) {
    return value == null ? value : convertUtcToTimeZone(value, useMainStore().getTimeZone(null), "yyyy-MM-DD hh:mm:ss A z");
}

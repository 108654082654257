<style lang="less" scoped>
@import "/styles/SiteColors.less";
button.close {
    position: fixed;
    top: 30px;
    right: 30px;
    color: #fff;
    font-size: 2em;
    opacity: 1;
}
.modal-content {
    background: transparent;
    border: unset;

    .search-group {
        label {
            font-size: 1.5em;
            color: #aaa;
            margin-bottom: 0;
            -webkit-transition: all 0.25s;
            transition: all 0.25s;
        }

        input#navSearch {
            background: none;
            height: 40px;
            color: #ddd;
            border: none;
            padding: 10px;
            border-bottom: 1px solid #aaa;
            border-radius: 0;
            box-shadow: none;
            padding-right: 1.75rem;

            /**
            * Remove default clear icon of search input type.
            * https://stackoverflow.com/a/77190241
            */
            appearance: textfield;
            outline-offset: -2px; // Fix outline style in Safari

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }

            &:focus {
                border-color: @link-color;
                border-bottom-width: 2px;
            }
        }

        .clear {
            position: absolute;
            right: 0;
            z-index: 3;

            &:focus {
                outline-offset: 0;
                outline-color: white;
            }
        }
    }

    .nav-search-results {
        width: calc(590px - 2rem);
    }
}
</style>
<template>
    <div class="modal-content" ref="container">
        <form id="navSearchForm" asp-antiforgery="false" @submit.prevent="searchTextSet(searchText2)" role="search">
            <button type="button" class="close icon icon-close" @click="close" aria-label="Close"></button>
            <div class="modal-body">
                <div class="form-group form-group-lg search-group">
                    <label for="navSearch">Search</label>
                    <div class="input-group align-items-center">
                        <!-- eslint-disable-next-line vue/no-mutating-props -->
                        <input v-debounce="searchTextSet" type="search" class="form-control" id="navSearch" ref="navSearch" aria-label="Search" autocomplete="off" v-model="searchText2" />
                        <button v-show="(searchText2 || '').length > 0" type="button" class="clear btn btn-bare icon icon-close text-white px-1 py-0" aria-label="Clear search" @click.stop="clearSearch"></button>
                    </div>
                </div>
                <Promised :promise="searchResultsPromise" v-slot:combined="{ isPending, data: searchData, error }" :pendingDelay="0" v-if="searchResultsPromise != null">
                    <div class="nav-search-results" id="searchResults" v-show="isPending || searchData != null || error != null">
                        <div class="nav-search-results-inner">
                            <span class="nav-search-results-caption">
                                Search results
                                {{ searchRootTenant ? "for all sites" : "for this site" }}
                            </span>
                            <div style="width: 100%">
                                <div v-show="isPending" style="display: none; width: 100%">
                                    <LoadingWheel :width="40" />
                                </div>
                                <div v-show="!isPending">
                                    <div v-show="searchData && !searchData.length" class="no_results_found" style="display: none; width: 100%">No results were found.</div>
                                    <div v-show="searchData && searchData.length" style="width: 100%">
                                        <div class="result" v-for="result in searchData">
                                            <Promised :promise="searchResultUrl(result)" v-slot="url">
                                                <a @click.prevent="gotoSearchResult(result)" :href="url">{{ result.label }}</a>
                                            </Promised>
                                            <Promised :promise="result.tenantDisplay" v-slot="tenantDisplay" v-if="result.tenant != data.tenantData.tenantCode">
                                                &nbsp;
                                                <span class="badge badge-light">{{ tenantDisplay }}</span>
                                            </Promised>
                                            <p style="margin-bottom: 5px">{{ result.description }}</p>
                                        </div>
                                    </div>
                                    <div v-show="searchData">
                                        <hr />
                                        <button class="btn btn-link" type="button" @click="searchRootTenant = !searchRootTenant">
                                            Search
                                            {{ searchRootTenant ? "in this site" : "all sites" }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Promised>
            </div>
        </form>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, inject, onMounted, toRef, SetupContext, ComputedRef } from "vue";
import { useMainStore, TenantData } from "./mainStore";
import { focusTrapSetup } from "@/lib/focusTrapMixin.3";
import { changePageInjectKey } from "@/AppShell.3.vue.InjectKeys";
import { Promised } from "vue-promised";
import LoadingWheel from "./lib/LoadingWheel.3.vue";
import type { SearchResults } from "@cs/SearchResults";
import type { UserData } from "@cs/UserData";
interface Props {
    data: { userData: UserData | null; tenantData: TenantData | null };
    searchText: string | null;
    updateSearchText: (text: string | null) => void;
}
export default defineComponent({
    props: ["data", "searchText", "updateSearchText"],
    emits: ["close"],
    components: { Promised, LoadingWheel },
    setup(props: Props, context: SetupContext) {
        const mainStore = useMainStore();
        const changePage = inject(changePageInjectKey)!;

        //$refs
        const navSearchRef = ref<HTMLInputElement>(null!);
        const containerRef = ref<HTMLElement>(null!);
        focusTrapSetup(containerRef);

        //States
        const searchText2 = toRef(props.searchText);
        const searchTextDebounced = ref(null as string | null);
        const searchRootTenant = ref(false as boolean);

        //Computed
        const searchResultsPromise = computed(async () => {
            if (searchTextDebounced.value == null || /^\s*$/.test(searchTextDebounced.value)) {
                return null;
            }
            const searchTextDebouncedVal = searchTextDebounced.value;
            const searchFromRoot = searchRootTenant.value;
            const searchUrl = await mainStore.getUrl("appShellSearch", {
                searchText: searchTextDebouncedVal,
                searchFromRoot: searchFromRoot,
            });
            const searchResultJsonPromise = fetch(searchUrl).then(i => i.json());
            mainStore.setLiveRegion(
                searchResultJsonPromise.then(search => {
                    return search.Results.length == 0 ? "No results were found." : `${search.Results.length} ${search.Results.length > 1 ? "results" : "result"} found.`;
                }),
            );
            const searchResultJson = (await searchResultJsonPromise) as SearchResults;
            const tenantDisplayLabel = mainStore.getTenantDisplayLabel(searchResultJson.Results.map(i => i.FormTenant));
            return searchResultJson.Results.map(v => ({
                tenant: v.FormTenant,
                tenantDisplay: tenantDisplayLabel[v.FormTenant],
                label: v.Label,
                description: v.Description,
                urlSlug: v.FormUrlSlug,
                formName: v.FormName,
                formVersion: v.FormVersion,
                formHash: v.FormHash,
                openInNewTab: v.OpenInNewTab,
            }));
        });

        //Methods
        const searchTextSet = (searchTextValue: string | null) => {
            searchTextDebounced.value = searchTextValue;
        };
        const clearSearch = () => {
            searchText2.value = null;
            searchTextDebounced.value = null;
            navSearchRef.value?.focus();
        };
        const close = () => {
            context.emit("close");
            props.updateSearchText(searchText2.value);
        };
        const gotoSearchResult = (result: SearchResult) => {
            changePage(
                {
                    page: "Page",
                    settings: {
                        areaCode: result.tenant,
                        urlSlug: result.urlSlug,
                        formName: result.formName,
                        formVersion: result.formVersion,
                        formHash: result.formHash,
                        page: 0,
                    },
                },
                false,
                false,
                !!result.openInNewTab,
            );
            close();
        };
        type extractComputedRef<Type> = Type extends ComputedRef<Promise<infer T>> ? T : Type extends ComputedRef<infer T2> ? T2 : never;
        type SearchResult = NonNullable<extractComputedRef<typeof searchResultsPromise>>[0];
        const searchResultUrl = (result: SearchResult) => {
            if (result.urlSlug) {
                return mainStore.getUrl("formGotoSlug", {
                    areaCode: result.tenant,
                    urlSlug: result.urlSlug,
                    page: 0,
                });
            } else {
                return mainStore.getUrl("formGoto", {
                    areaCode: result.tenant,
                    formName: result.formName,
                    formVersion: result.formVersion,
                    formHash: result.formHash,
                    page: 0,
                });
            }
        };

        onMounted(() => searchTextSet(searchText2.value));

        return {
            container: containerRef,
            navSearch: navSearchRef,
            searchText2,
            searchRootTenant,
            searchResultsPromise,
            gotoSearchResult,
            searchResultUrl,
            searchTextSet,
            clearSearch,
            close,
        };
    },
});
</script>

import { InjectionKey } from "vue";
export interface ChangePageEvent {
    page?: string;
    settings?: { [key: string]: string | number | boolean | null | undefined };
    areaUrlSlug?: string;
}
export interface ModalEvent {
    stop: () => void;
    // TODO others?
}
export type changePage = (event: ChangePageEvent, replaceHistory?: boolean, skipHistory?: boolean, openInNewTab?: boolean) => void;
export const changePageInjectKey = Symbol() as InjectionKey<changePage>;
export type updatePage = (event: ChangePageEvent["settings"], replaceHistory?: boolean) => void;
export const updatePageInjectKey = Symbol() as InjectionKey<updatePage>;
export type getLoginUrl = (type?: number | string, returnUrl?: string) => Promise<string>;
export const getLoginUrlInjectKey = Symbol() as InjectionKey<getLoginUrl>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type showModal = (component: any, params: any, events?: any, bind?: any) => Promise<void>;
export const showModalInjectKey = Symbol() as InjectionKey<showModal>;
export type loadUserData = () => Promise<void>;
export const loadUserDataInjectKey = Symbol() as InjectionKey<loadUserData>;

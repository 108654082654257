<style>
@media (max-width: 767px) {
    .dropdown-menu {
        position: static;
    }
}
.previous-question {
    background: none;
    width: 100%;
    display: block;
    border-bottom: none;
    border-left: none;
    border-right: none;
}
.dropdown-menu.download-menu {
    position: absolute;
    padding: 5px;
    min-width: 0;
    max-width: 320px;
    right: 0;
    left: auto;
}
.dropdown-menu.download-menu a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.returning_user .nav-link {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
}
.tab-content {
    padding-left: 0;
    padding-right: 0;
}
.help-block {
    padding: 0.5rem;
}
.testMode {
    background: #222;
    color: #fff;
    text-align: center;
}
</style>
<template>
    <Promised :promise="indexDataPromise!" :pendingDelay="200" v-slot:combined="{ isPending, isDelayElapsed, data }">
        <LoadingWheel :width="100" v-if="isPending && data == null" />
        <template v-else>
            <div class="index-container" :class="{ 'logged-in': showSideBar }">
                <div class="returning_user" v-show="showSideBar" v-if="showSideBar">
                    <div role="complementary" aria-labelledby="MyFormsHeading">
                        <div class="returning_user_header">
                            <h1 id="MyFormsHeading">
                                My Forms
                                <template v-if="isPending && isDelayElapsed">
                                    <LoadingWheel :width="24" style="display: inline-block" />
                                </template>
                            </h1>
                        </div>
                        <div class="returning_user_body">
                            <ul class="nav nav-tabs">
                                <li @click="tab = 'to-do'" class="nav-item">
                                    <a :class="{ active: tab == 'to-do' || tab == null }" class="todo_forms_tab nav-link" tabindex="0" role="button">
                                        To Do
                                        <span class="badge badge-light" v-if="activeForms.length > 0">{{ activeForms.length }}</span>
                                    </a>
                                </li>
                                <li @click="tab = 'in-progress'" class="nav-item">
                                    <a :class="{ active: tab == 'in-progress' }" class="draft_forms_tab nav-link" tabindex="0" role="button">
                                        In Progress
                                        <span class="badge badge-light" v-if="inProgressForms.length > 0">{{ inProgressForms.length }}</span>
                                    </a>
                                </li>
                                <li @click="tab = 'done'" class="nav-item">
                                    <a :class="{ active: tab == 'done' }" class="submitted_forms_tab nav-link" tabindex="0" role="button">Done</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <!-- To Do tab -->
                                <div id="ToDoTab" class="tab-pane fade show" :class="{ active: tab == 'to-do' || tab == null, show: tab == 'to-do' || tab == null }">
                                    <ul class="returning_user_list_of_forms" v-show="activeForms.length > 0">
                                        <li v-for="(item, itemIndex) in activeFormsSortedByLastUpdated">
                                            <div>
                                                {{ item.effectiveDisplayLabel() }}
                                            </div>
                                            <ul v-if="item.visibleActiveTasks().length > 1">
                                                <li v-for="task in item.visibleActiveTasks()">{{ task.DisplayLabel }}</li>
                                            </ul>
                                            <span class="submitted_timestamp" title="Last Updated">{{ localeDateTime(item.lastUpdated?.toISOString()) }}</span>
                                            <div class="returning_user_actions">
                                                <Promised :promise="gotoDraftUrl(item)" v-slot:combined="{ data: url }">
                                                    <a class="btn btn-xs btn-link" :id="'gotoBtn_ToDo_' + itemIndex" tabindex="0" role="button" v-show="url != null && !item.isDiscontinued && !item.isExpired" @click.prevent="gotoDraft(item)" :href="url">
                                                        Continue<span class="sr-only"> {{ item.effectiveDisplayLabel() }}</span>
                                                    </a>
                                                </Promised>
                                                <a class="btn btn-xs btn-link" tabindex="0" role="button" :id="'deleteBtn_' + itemIndex" @click.prevent="confirmDelete(item, $event)" v-if="isDeletable(item)">
                                                    Delete<span class="sr-only"> {{ item.effectiveDisplayLabel() }}</span>
                                                </a>
                                            </div>
                                            <div v-show="item.isExpired" class="my-forms-tag unavailable">This form is no longer available.</div>
                                            <div v-show="item.isDiscontinued" class="my-forms-tag unavailable">This form has been discontinued.</div>
                                            <div v-show="item.isReturned" class="my-forms-tag returned">This form was returned.</div>
                                            <span v-if="!item.isReturned && !item.isDiscontinued && !item.isExpired && item.visibleActiveTasks().length == 1" class="my-forms-tag in-progress">{{ item.visibleActiveTasks()[0].DisplayLabel }}</span>
                                            <div v-if="item.testMode" class="my-forms-tag testMode">Test Submission</div>
                                        </li>
                                    </ul>
                                    <p class="help-block" v-show="activeForms.length == 0">You do not currently have any items to do.</p>
                                </div>
                                <!-- In Progress tab -->
                                <!-- TODO dedup these tabs -->
                                <div id="InProgressTab" class="tab-pane fade show" :class="{ active: tab == 'in-progress', show: tab == 'in-progress' }">
                                    <ul class="returning_user_list_of_forms" v-show="inProgressForms.length > 0">
                                        <li v-for="item in inProgressFormsSortedBySubmitDate">
                                            <div>
                                                {{ item.effectiveDisplayLabel() }}
                                            </div>
                                            <ul v-if="item.visibleActiveTasks().length > 1">
                                                <li v-for="task in item.visibleActiveTasks()">{{ task.DisplayLabel }}</li>
                                            </ul>
                                            <span class="submitted_timestamp" title="Submitted Date">{{ localeDateTime(item.submitDate?.toISOString()) }}</span>
                                            <div class="returning_user_actions">
                                                <div v-if="item.hasFileUploads || item.viewTypes.length > 1" class="dropdown">
                                                    <a tabindex="0" role="button" class="dropdown-toggle btn btn-xs btn-link" @click.prevent.stop="toggleDropdown($event)" aria-haspopup="menu" :aria-controls="`${item.id}_DownloadOptions`">Download<span class="sr-only"> Options</span></a>
                                                    <div class="dropdown-menu download-menu" role="menu" :aria-label="`${item.publicDisplayLabel} Download Options`" :id="`${item.id}_DownloadOptions`">
                                                        <template v-for="view in item.viewTypes">
                                                            <Promised :promise="gotoViewUrl(item, view)" v-slot:combined="{ data: url }">
                                                                <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="menuitem" @click.prevent="gotoView(item, view)" :href="url" target="_blank"><span class="sr-only">Download </span>{{ view == "Builtin" || view == "Default" ? "Submission" : view }}</a>
                                                            </Promised>
                                                        </template>
                                                        <Promised :promise="gotoViewAttachmentsUrl(item)" v-slot:combined="{ data: url }" v-if="item.hasFileUploads">
                                                            <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="menuitem" @click.prevent="gotoViewAttachments(item)" :href="url" target="_blank"><span class="sr-only">Download </span>Attached File(s)</a>
                                                        </Promised>
                                                        <Promised :promise="gotoViewAllDocumentsUrl(item)" v-slot:combined="{ data: url }" v-if="item.hasFileUploads || item.viewTypes.length > 1">
                                                            <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="menuitem" @click.prevent="gotoViewAllDocuments(item)" :href="url" target="_blank"><span class="sr-only">Download </span>All Downloads</a>
                                                        </Promised>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <template v-for="view in item.viewTypes">
                                                        <Promised :promise="gotoViewUrl(item, view)" v-slot:combined="{ data: url }">
                                                            <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="button" @click.prevent="gotoView(item, view)" :href="url" target="_blank"><span class="sr-only">Download </span>{{ view == "Builtin" || view == "Default" ? "Submission" : view }}</a>
                                                        </Promised>
                                                    </template>
                                                </div>
                                            </div>
                                            <span class="my-forms-tag in-progress">{{ item.effectiveInProgressStatusLabel() }}</span>
                                            <div v-if="item.testMode" class="my-forms-tag testMode">Test Submission</div>
                                        </li>
                                    </ul>
                                    <p class="help-block" v-show="inProgressForms.length == 0">You do not currently have any items in progress.</p>
                                </div>
                                <!-- Done tab -->
                                <div id="DoneTab" class="tab-pane fade" :class="{ active: tab == 'done', show: tab == 'done' }">
                                    <ul class="returning_user_list_of_forms" v-show="submittedForms.length > 0">
                                        <li v-for="item in completedFormsSortedBySubmitDate">
                                            <div>
                                                {{ item.effectiveDisplayLabel() }}
                                            </div>
                                            <span class="submitted_timestamp" title="Submitted Date">{{ localeDateTime(item.submitDate?.toISOString()) }}</span>
                                            <div class="returning_user_actions">
                                                <div v-if="item.hasFileUploads || item.viewTypes.length > 1" class="dropdown">
                                                    <a tabindex="0" role="button" class="dropdown-toggle btn btn-xs btn-link" @click.prevent.stop="toggleDropdown($event)" aria-haspopup="menu" :aria-controls="`${item.id}_DownloadOptions`">Download<span class="sr-only"> Options</span></a>
                                                    <div class="dropdown-menu download-menu" role="menu" :aria-label="`${item.publicDisplayLabel} Download Options`" :id="`${item.id}_DownloadOptions`">
                                                        <template v-for="view in item.viewTypes">
                                                            <Promised :promise="gotoViewUrl(item, view)" v-slot:combined="{ data: url }">
                                                                <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="menuitem" @click.prevent="gotoView(item, view)" :href="url" target="_blank"><span class="sr-only">Download </span>{{ view == "Builtin" || view == "Default" ? "Submission" : view }}</a>
                                                            </Promised>
                                                        </template>
                                                        <Promised :promise="gotoViewAttachmentsUrl(item)" v-slot:combined="{ data: url }" v-if="item.hasFileUploads">
                                                            <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="menuitem" @click.prevent="gotoViewAttachments(item)" :href="url" target="_blank"><span class="sr-only">Download </span>Uploaded File(s)</a>
                                                        </Promised>
                                                        <Promised :promise="gotoViewAllDocumentsUrl(item)" v-slot:combined="{ data: url }" v-if="item.hasFileUploads || item.viewTypes.length > 1">
                                                            <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="menuitem" @click.prevent="gotoViewAllDocuments(item)" :href="url" target="_blank"><span class="sr-only">Download </span>All Downloads</a>
                                                        </Promised>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <template v-for="view in item.viewTypes">
                                                        <Promised :promise="gotoViewUrl(item, view)" v-slot:combined="{ data: url }">
                                                            <a v-show="url != null" class="btn btn-xs btn-link" tabindex="0" role="button" @click.prevent="gotoView(item, view)" :href="url" target="_blank"><span class="sr-only">Download </span>{{ view == "Builtin" || view == "Default" ? "Submission" : view }}</a>
                                                        </Promised>
                                                    </template>
                                                </div>
                                            </div>
                                            <span class="my-forms-tag done">{{ item.effectiveDoneStatusLabel() }}</span>
                                            <div v-if="item.testMode" class="my-forms-tag testMode">Test Submission</div>
                                        </li>
                                    </ul>
                                    <p class="help-block" v-show="completedForms.length == 0">You do not currently have any completed items.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="index-content">
                    <div class="container tile">
                        <div v-if="contentHtml" v-html="contentHtml" role="main" aria-label="Home Page Content"></div>
                        <div v-if="!contentHtml" role="main" aria-label="Home Page Content">
                            <div class="text-center">
                                <div><img src="../../images/accessgov_empowered_homepage_logo.png" class="index-logo" alt="" /></div>
                            </div>
                            <br />
                            <div class="text-center">
                                <p class="lead">Use the search bar, guided search, or directory to interact with government-related applications and content.</p>
                            </div>
                        </div>
                        <br />
                        <div class="text-center" id="questionsDiv" v-if="questionRootNode != null && currentQuestion != null" role="complementary" aria-label="Guided Search">
                            <!--
                            @*<div data-bind="visible: false">
                                <LoadingWheel :width="100" />
                            </div>
                            <div style="display: none;" data-bind="visible: true">*@
                            -->
                            <div>
                                <button ref="previousAnswersRef" @click="priorAnswerClick(answer)" class="previous-question" v-for="answer in previousAnswers" aria-description="Click to return to this question">
                                    <span class="d-block">
                                        <span class="sr-only">Question:&nbsp;</span>
                                        <strong>{{ answer.parentQuestion.text }}</strong>
                                    </span>
                                    <span class="d-block">
                                        <span class="sr-only">&comma;&nbsp;Answer:&nbsp;</span>
                                        {{ answer.answer.text }}
                                        <HelpIcon :text="answer.answer.helpText" aria-hidden="true" />
                                    </span>
                                </button>
                            </div>
                            <div id="question1" class="current-question">
                                <fieldset>
                                    <legend style="margin-bottom: 5px; padding-top: 15px; border: none">{{ currentQuestion.text }}</legend>
                                    <div class="text-left" style="display: inline-block">
                                        <template v-for="answer in currentQuestion.answers">
                                            <div class="form-check answer" :class="{ disabled: !answer.valid }">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="radio" name="question1" @click="answerClick($event, answer)" :value="answer" v-model="selectedAnswer" :disabled="!answer.valid" />
                                                    {{ answer.text }}
                                                    <HelpIcon :text="answer.helpText" />
                                                    <small v-show="!answer.valid" style="color: red">(answer is no longer valid)</small>
                                                </label>
                                            </div>
                                        </template>
                                    </div>
                                </fieldset>
                                <div v-show="selectedAnswer != null">
                                    <button tabindex="0" @click="answerClick(null, selectedAnswer!)">Next</button>
                                </div>
                            </div>
                            <div class="current-question" v-show="currentQuestionLoading">
                                <LoadingWheel :width="100" />
                            </div>
                            <!--</div>-->
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Promised>
</template>
<script lang="ts">
import { QuestionNode as QuestionNodeData } from "@cs/QuestionNode";
import { QuestionLinkNode as QuestionLinkNodeData } from "@cs/QuestionLinkNode";
import { FormNode as FormNodeData } from "@cs/FormNode";
import { AnswerNode as AnswerNodeData } from "@cs/AnswerNode";
import { Object as Node } from "@cs/Node";
import { FormListItem } from "@cs/FormListItem";
import { UserTask } from "@cs/UserTask";
import { Index as IndexVm } from "@cs/Index";
import delayPromise from "@/lib/delayPromise";

class Submission {
    id: string;
    areaCode: string;
    formName: string;
    formVersion: string;
    formHash: string;
    formTitle?: string;
    publicDisplayLabel?: string;
    submitterStatusLabel?: string;
    lastUpdated: Date;
    submitDate: Date | null;
    viewTypes: string[];
    hasFileUploads: boolean;
    testMode: boolean;
    isExpired: boolean;
    isDeletable: boolean;
    isDiscontinued: boolean;
    isReturned: boolean;
    activeTasks: UserTask[];

    constructor(data: FormListItem) {
        this.id = data.Id!;
        this.areaCode = data.Tenant;
        this.formName = data.FormName;
        this.formVersion = data.FormVersion;
        this.formHash = data.FormHash;
        this.formTitle = data.FormTitle;
        this.publicDisplayLabel = data.PublicDisplayLabel;
        this.submitterStatusLabel = data.SubmitterStatusLabel;
        this.lastUpdated = new Date(data.LastUpdated);
        this.submitDate = data.SubmitDate == null ? null : new Date(data.SubmitDate);
        this.viewTypes = data.ViewTypes!;
        this.hasFileUploads = data.HasFileUploads;
        this.testMode = data.TestMode;
        this.isExpired = data.IsExpired;
        this.isDeletable = data.IsDeletable;
        this.isDiscontinued = data.IsDiscontinued;
        this.isReturned = data.IsReturned;
        this.activeTasks = data.UserTasks.filter(i => [UserTaskStatus.Active, UserTaskStatus.WaitingAssignment, UserTaskStatus.Returned].includes(i.Status));
    }

    visibleActiveTasks() {
        return this.activeTasks.filter(i => i.DisplayLabel != null && !/^\s*$/.test(i.DisplayLabel));
    }

    effectiveDisplayLabel() {
        var displayLabel = this.publicDisplayLabel;
        if (displayLabel == null || /^\s*$/.test(displayLabel)) {
            displayLabel = this.formTitle;
        }
        return displayLabel;
    }

    effectiveInProgressStatusLabel() {
        return this.submitterStatusLabel || "In Progress";
    }

    effectiveDoneStatusLabel() {
        return this.submitterStatusLabel || "Done";
    }
}

class QuestionNode {
    text: string;
    answers: AnswerNode[];
    constructor(data: QuestionNodeData) {
        this.text = data.QuestionText;
        this.answers = data.Children.map(d => new AnswerNode(d as AnswerNodeData));
    }
}

class AnswerNode {
    text: string;
    helpText?: string;
    type: string;
    question: QuestionNode | null | undefined = null;
    linkedId: string | null | undefined = null;
    linkedTenant: string | null | undefined = null;
    link: Promise<QuestionNode | undefined> | null | undefined = null;
    valid = false;
    formUrlSlug: string | null | undefined = null;
    formName: string | null | undefined = null;
    formVersion: string | null | undefined = null;
    formHash: string | null | undefined = null;
    formTenant: string | null | undefined = null;
    openInNewTab: boolean | null | undefined = null;

    constructor(data: AnswerNodeData) {
        this.text = data.AnswerText;
        this.helpText = data.HelpText;
        const child = data.Children[0] as Node;
        if (this.isQuestion(child)) {
            this.type = "SubQuestion";
            this.question = new QuestionNode(child as QuestionNodeData);
            this.valid = true;
        } else if (this.isLink(child)) {
            this.type = "Link";
            this.linkedId = child.LinkedId;
            this.linkedTenant = child.Tenant;
            this.link = null;
            this.valid = true;
        } else if (this.isForm(child)) {
            this.type = "Page";
            this.formUrlSlug = child.FormUrlSlug;
            this.formName = child.FormName;
            this.formVersion = child.FormVersion;
            this.formHash = child.FormHash;
            this.formTenant = child.FormTenant;
            this.valid = true; // TODO?
            this.openInNewTab = child.OpenInNewTab;
        } else {
            this.valid = false;
            throw new Error(`Unknown type: ${JSON.stringify(child)}`);
        }
    }

    isQuestion(node: Node): node is QuestionNodeData {
        return !!(node as QuestionNodeData).QuestionText;
    }

    isLink(node: Node): node is QuestionLinkNodeData {
        return !!(node as QuestionLinkNodeData).LinkedId;
    }

    isForm(node: Node): node is FormNodeData {
        return !!(node as FormNodeData).FormName;
    }

    resolveLink(urlFetch: ReturnType<typeof useMainStore>["getUrl"]) {
        if (this.type != "Link" || this.link != null) {
            return;
        }
        this.link = (async () => {
            const url = await urlFetch("getQuestionData", { tenant: this.linkedTenant, rootId: this.linkedId });
            const data = await fetch(url);
            if (data.status == 200) {
                const json = await data.json();
                return new QuestionNode(json);
            } else {
                this.valid = false;
            }
        })();
    }
}

import { defineComponent, ref, computed, watch, inject, onActivated, Ref } from "vue";
import { localeDateTime } from "@/lib/localeDatetime.3";
import { asyncComputed } from "@vueuse/core";
import { useMainStore } from "../../mainStore";
import { UserTaskStatus } from "@cs/UserTaskStatus";
import LoadingWheel from "@/lib/LoadingWheel.3.vue";
import HelpIcon from "@/lib/HelpIcon.3.vue";
import { Promised } from "vue-promised";
import { showModalInjectKey, showModal, changePageInjectKey, changePage, ModalEvent } from "@/AppShell.3.vue.InjectKeys";
import DeleteConfirmModal from "./dialogs/DeleteConfirm.3.vue";
import { fetchXrsf } from "@/lib/fetchHelpers.3";
import { toggleDropdown } from "@/lib/toggleDropdown.3";
interface ViewData {
    activeForms: Submission[];
    submittedForms: Submission[];
    contentHtml?: string;
    questionRootNode: QuestionNode | null;
}
interface AnswerQuestion {
    parentQuestion: QuestionNode;
    answer: AnswerNode;
}
export default defineComponent({
    components: {
        LoadingWheel,
        HelpIcon,
        Promised,
    },
    props: ["settings"],
    emits: ["changePage", "updatePage"], // TODO remove?
    setup() {
        const previousAnswersRef = ref<HTMLInputElement[]>(null!);
        const firstLoad = ref(true);
        const indexDataPromise = ref<Promise<ViewData> | null>(null);
        const showSideBar = ref(true);
        const tab = ref<"done" | "in-progress" | "to-do" | null>(null);
        const previousAnswers = ref<AnswerQuestion[]>([]);
        const currentQuestion = ref<null | QuestionNode>(null);
        const selectedAnswer = ref<null | AnswerNode>(null);
        const currentQuestionLoading = ref(false);

        const mainStore = useMainStore();
        const showModal = inject(showModalInjectKey)! as showModal;
        const changePage = inject(changePageInjectKey)! as changePage;

        const activeFormsSortedByLastUpdated = computed(() => {
            return activeForms.value.slice().sort((a, b) => (a.lastUpdated == b.lastUpdated ? 0 : a.lastUpdated < b.lastUpdated ? 1 : -1));
        });
        const inProgressForms = computed(() => {
            return submittedForms.value.filter(i => i.activeTasks.length > 0);
        });
        const completedForms = computed(() => {
            return submittedForms.value.filter(i => i.activeTasks.length == 0);
        });
        const inProgressFormsSortedBySubmitDate = computed(() => {
            return inProgressForms.value.slice().sort((a, b) => (a.submitDate == b.submitDate ? 0 : (a.submitDate ?? 10000000) < (b.submitDate ?? 10000000) ? 1 : -1));
        });
        const completedFormsSortedBySubmitDate = computed(() => {
            return completedForms.value.slice().sort((a, b) => (a.submitDate == b.submitDate ? 0 : a.submitDate! < b.submitDate! ? 1 : -1));
        });

        const activeForms = asyncComputed(
            async () => {
                const data = await indexDataPromise.value;
                return data == null ? [] : data.activeForms;
            },
            [] as ViewData["activeForms"],
        );
        const submittedForms = asyncComputed(
            async () => {
                const data = await indexDataPromise.value;
                return data == null ? [] : data.submittedForms;
            },
            [] as ViewData["submittedForms"],
        );
        const contentHtml = asyncComputed(async () => {
            const data = await indexDataPromise.value;
            return data == null ? [] : data.contentHtml;
        }, "Loading...") as unknown as Ref<string>;
        const questionRootNode = asyncComputed(async () => {
            const data = await indexDataPromise.value;
            return data == null ? null : data.questionRootNode;
        });

        watch(
            () => questionRootNode.value,
            val => {
                if (currentQuestion.value == null) {
                    currentQuestion.value = val;
                }
                val?.answers.forEach(answer => answer.resolveLink(mainStore.getUrl));
            },
        );

        const gotoDraftUrl = (item: Submission) => {
            return mainStore.getUrl("draftGoto", {
                tenant: mainStore.tenantCode,
                areaCode: item.areaCode,
                formId: item.id,
                page: 0,
            });
        };
        const gotoDraft = (item: Submission) => {
            changePage({
                page: "Page",
                settings: {
                    areaCode: item.areaCode,
                    formId: item.id,
                    formName: item.formName,
                    formVersion: item.formVersion,
                    formHash: item.formHash,
                    page: 0,
                },
            });
        };
        const isDeletable = (item: Submission) => {
            return item.isDeletable;
        };
        const confirmDelete = async (item: Submission, event: Event) => {
            await mainStore.ensureSiteCssLoaded();
            let deleting = false;
            showModal(
                DeleteConfirmModal,
                {
                    // TODO styling without bootstrap/sitecss
                    deleteFunction: async () => {
                        if (!deleting) {
                            deleting = true;
                            const url = await mainStore.getUrl("deleteDraft", { asAdmin: false, tenantCode: item.areaCode, formId: item.id });
                            const response = await fetchXrsf(mainStore, url, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                credentials: "same-origin",
                            });
                            deleting = false;
                            if (response.status == 200) {
                                loadData();
                            } else {
                                throw new Error("Not 200 OK: " + response.status + " " + response.statusText);
                            }
                        }
                    },
                    triggerElement: event.target,
                },
                {
                    "before-close": (event: ModalEvent) => {
                        if (deleting) {
                            event.stop();
                        }
                    },
                },
            );
        };
        const loadData = async () => {
            await mainStore.ensureSiteCssLoaded();
            indexDataPromise.value = (async () => {
                const url = await mainStore.getUrl("getIndexData");
                const response = await fetch(url, { cache: "reload" });
                const data = (await response.json()) as IndexVm;
                mainStore.hasSession = data.HasSubmissions;
                return {
                    activeForms: data.ActiveForms.map(d => new Submission(d)),
                    submittedForms: data.SubmittedForms.map(d => new Submission(d)),
                    contentHtml: data.ContentHtml,
                    questionRootNode: data.RootNode != null ? new QuestionNode(data.RootNode as QuestionNodeData) : null,
                };
            })();
            await indexDataPromise.value;
        };
        const gotoViewUrl = (item: Submission, view: string) => {
            return mainStore.getUrl("downloadDocument", {
                tenantCode: item.areaCode,
                formId: item.id,
                document: view,
            });
        };
        const gotoView = async (item: Submission, view: string) => {
            window.open(await gotoViewUrl(item, view), "_blank");
        };
        const gotoViewAttachmentsUrl = (item: Submission) => {
            return mainStore.getUrl("submissionGotoAttachments", {
                tenantCode: item.areaCode,
                formId: item.id,
            });
        };
        const gotoViewAttachments = async (item: Submission) => {
            window.open(await gotoViewAttachmentsUrl(item), "_blank");
        };
        const gotoViewAllDocumentsUrl = (item: Submission) => {
            return mainStore.getUrl("submissionGotoAllDocuments", {
                tenantCode: item.areaCode,
                formId: item.id,
            });
        };
        const gotoViewAllDocuments = async (item: Submission) => {
            window.open(await gotoViewAllDocumentsUrl(item), "_blank");
        };
        const answerClick = async (event: MouseEvent | null, answer: AnswerNode) => {
            if (event != null && event.pageX == 0 && event.pageY == 0) {
                // Enter key
                selectedAnswer.value = answer;
            } else {
                if (event != null) {
                    event.preventDefault();
                }
                if (answer.type == "SubQuestion") {
                    previousAnswers.value.push({ parentQuestion: currentQuestion.value!, answer: answer });
                    currentQuestion.value = answer.question!;
                    selectedAnswer.value = null;
                    currentQuestion.value!.answers.forEach(answer => answer.resolveLink(mainStore.getUrl));
                } else if (answer.type == "Link") {
                    currentQuestionLoading.value = true;
                    answer.link!.then(linkedQuestion => {
                        if (linkedQuestion) {
                            previousAnswers.value.push({ parentQuestion: currentQuestion.value!, answer: answer });
                            selectedAnswer.value = null;
                            currentQuestion.value = linkedQuestion;
                        }
                        currentQuestionLoading.value = false;
                    });
                } else if (answer.type == "Page") {
                    selectedAnswer.value = null;
                    changePage(
                        {
                            page: "Page",
                            settings: {
                                areaCode: answer.formTenant,
                                urlSlug: answer.formUrlSlug,
                                formName: answer.formName,
                                formVersion: answer.formVersion,
                                formHash: answer.formHash,
                                page: 0,
                            },
                        },
                        false,
                        false,
                        !!answer.openInNewTab,
                    );
                } else {
                    throw new Error(`Unknown type: ${answer.type}`);
                }
            }
            focusFirstAnswer();
        };
        const priorAnswerClick = (answerQuestion: AnswerQuestion) => {
            previousAnswers.value.splice(previousAnswers.value.indexOf(answerQuestion));
            currentQuestion.value = answerQuestion.parentQuestion;
            selectedAnswer.value = null;
            focusFirstAnswer();
        };
        const focusFirstAnswer = async () => {
            await delayPromise(0);
            if (selectedAnswer.value == null) {
                previousAnswersRef.value[0]?.focus();
            }
        };

        onActivated(async () => {
            await loadData();
        });

        return {
            //$refs
            previousAnswersRef,

            // Refs
            firstLoad,
            indexDataPromise,
            showSideBar,
            tab,
            previousAnswers,
            currentQuestion,
            selectedAnswer,
            currentQuestionLoading,

            //computed
            activeFormsSortedByLastUpdated,
            inProgressForms,
            completedForms,
            inProgressFormsSortedBySubmitDate,
            completedFormsSortedBySubmitDate,
            activeForms,
            submittedForms,
            contentHtml,
            questionRootNode,

            //methods
            gotoDraftUrl,
            gotoDraft,
            isDeletable,
            confirmDelete,
            loadData,
            gotoViewUrl,
            gotoView,
            gotoViewAttachmentsUrl,
            gotoViewAttachments,
            gotoViewAllDocumentsUrl,
            gotoViewAllDocuments,
            answerClick,
            priorAnswerClick,

            toggleDropdown,
            localeDateTime,
        };
    },
});
</script>

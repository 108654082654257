//cspell:ignore vnode, describedby
import { ref, onMounted, onUnmounted, getCurrentInstance, Ref } from "vue";
import { focusTrapSetup } from "@/lib/focusTrapMixin.3";

export const dialogProps = ["triggerElement"];
export const dialogPropsObject = { triggerElement: [HTMLElement, String] };
export type DialogProps = {
    triggerElement: HTMLElement | string;
};
export function dialogSetup(containerRef: Ref<HTMLElement>, props?: DialogProps) {
    const focusTrap = focusTrapSetup(containerRef);
    const componentInstance = getCurrentInstance();
    const dialogRenderPromise = ref<Promise<void>>(null!);
    const linkModalDescriptionTexts = () => {
        const describedbyElements: HTMLElement[] = Array.from(containerRef.value?.querySelectorAll("[describedby-text]"));
        if (describedbyElements.length > 0) {
            const describedbyTexts: string[] = [];
            describedbyElements.forEach((elem: HTMLElement, index: number) => {
                const modalDescriptionText = `modal-description-text-${componentInstance?.uid}-${index}`;
                (elem as HTMLElement).setAttribute("id", modalDescriptionText);
                describedbyTexts.push(modalDescriptionText);
            });
            containerRef.value?.closest(".modal-container")?.setAttribute("aria-describedby", describedbyTexts.join(" "));
        }
    };

    onMounted(async () => {
        containerRef.value?.querySelector(".modal-title")?.setAttribute("id", `modal-header-text-${componentInstance?.uid}`);
        containerRef.value?.closest(".modal-container")?.setAttribute("aria-labelledby", `modal-header-text-${componentInstance?.uid}`);

        if (dialogRenderPromise.value != null) {
            containerRef.value?.closest(".vfm__content")?.setAttribute("tabindex", "-1");
            // Disable focus trap in order to focus modal container temporarily.
            // This will enable screen readers to properly scan the label and all of its linked descriptions.
            focusTrap.focusTrapSetEnabled(false);
            (containerRef.value?.closest(".vfm__content") as HTMLElement)?.focus();
            await dialogRenderPromise.value;
            await new Promise(resolve => setTimeout(resolve, 0));
            linkModalDescriptionTexts();
            // Re-enable focus trap.
            focusTrap.focusTrapSetEnabled(true);
        } else {
            linkModalDescriptionTexts();
        }
    });

    onUnmounted(async () => {
        if (!props?.triggerElement) return;
        if (typeof props.triggerElement === "string") {
            await new Promise(resolve => setTimeout(resolve, 0));
            (document.querySelector(props.triggerElement as string) as unknown as HTMLElement)?.focus();
        } else {
            props.triggerElement?.focus();
        }
    });

    return {
        dialogRenderPromise,
        ...focusTrap,
    };
}

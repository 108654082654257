// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle[data-v-a0d89cb6] {
  cursor: pointer;
}
.user-control a[data-v-a0d89cb6] {
  color: #fff;
}
.user-control a.icon[data-v-a0d89cb6] {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.user-control a.icon[data-v-a0d89cb6]:before {
  color: #fff;
  margin-right: 0.5rem;
}
.user-control a.icon[data-v-a0d89cb6]:after {
  color: #fff;
  margin-left: 0.5rem;
}
.navbar .user-menu[data-v-a0d89cb6] {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./AppShell.3.vue","webpack://./../AppShell.3.vue"],"names":[],"mappings":"AACA;EACI,eAAA;ACAJ;ADEA;EACI,WAAA;ACAJ;ADCI;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;ACCR;ADAQ;EACI,WAAA;EACA,oBAAA;ACEZ;ADAQ;EACI,WAAA;EACA,mBAAA;ACEZ;ADEA;EACI,gBAAA;ACAJ","sourcesContent":["\n.dropdown-toggle {\n    cursor: pointer;\n}\n.user-control a {\n    color: #fff;\n    &.icon {\n        display: flex;\n        align-items: center;\n        text-decoration: none;\n        &:before {\n            color: #fff;\n            margin-right: 0.5rem;\n        }\n        &:after {\n            color: #fff;\n            margin-left: 0.5rem;\n        }\n    }\n}\n.navbar .user-menu {\n    margin-top: 10px;\n}\n",".dropdown-toggle {\n  cursor: pointer;\n}\n.user-control a {\n  color: #fff;\n}\n.user-control a.icon {\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n}\n.user-control a.icon:before {\n  color: #fff;\n  margin-right: 0.5rem;\n}\n.user-control a.icon:after {\n  color: #fff;\n  margin-left: 0.5rem;\n}\n.navbar .user-menu {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
[data-v-9e77df10] .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
[data-v-9e77df10] .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin: 0 1rem; */
    padding: 0.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    /*transform: scale(0.95);*/
    width: min(90vw, 600px);
}
/*
::v-deep(.modal-header) {
    font-size: 1.5rem;
    font-weight: 700;
}
*/
.dark-mode div[data-v-9e77df10] .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
`, "",{"version":3,"sources":["webpack://./ModalWrapper.3.vue"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;IAChB,0BAA0B;IAC1B,uBAAuB;AAC3B;AACA;;;;;CAKC;AACD;IACI,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":["<style scoped>\n::v-deep(.modal-container) {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n::v-deep(.modal-content) {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    /* margin: 0 1rem; */\n    padding: 0.3rem;\n    border: 1px solid #e2e8f0;\n    border-radius: 0.25rem;\n    background: #fff;\n    /*transform: scale(0.95);*/\n    width: min(90vw, 600px);\n}\n/*\n::v-deep(.modal-header) {\n    font-size: 1.5rem;\n    font-weight: 700;\n}\n*/\n.dark-mode div::v-deep(.modal-content) {\n    border-color: #2d3748;\n    background-color: #1a202c;\n}\n</style>\n<template>\n    <vue-final-modal v-slot=\"{ close, params }\" :focus-retain=\"false\" z-index=\"1002\" v-bind=\"$attrs\" classes=\"modal-container\">\n        <component :is=\"params.innerComponent\" v-bind=\"params.innerParams\" @close=\"close\"></component>\n    </vue-final-modal>\n</template>\n<script lang=\"ts\">\nimport { defineComponent } from \"vue\";\nexport default defineComponent({\n    props: [\"innerComponent\", \"innerParams\"],\n    inheritAttrs: false,\n});\n</script>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

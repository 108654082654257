<template>
    <div class="modal-content" ref="container">
        <template v-if="wasLoggedIn">
            <div class="modal-header">
                <h2 class="modal-title">Re-Log In Required</h2>
            </div>
            <div class="modal-body">
                <template v-if="isLoggedIn">Your login is invalid for this area. You must log in again to continue.</template>
                <template v-else>Your login has expired. Log in again to preserve your changes.</template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" id="loginBtn" @click="login">Log In</button>
            </div>
        </template>
        <template v-else>
            <div class="modal-header">
                <h2 class="modal-title">Session Expired</h2>
            </div>
            <div class="modal-body">Your session has expired. You must start over.</div>
            <div class="modal-footer">
                <!--
                <a class="btn btn-primary" :href="homeUrl">Home</a>
                -->
            </div>
        </template>
    </div>
</template>
<script lang="ts">
import { defineComponent, inject, ref, SetupContext } from "vue";
import { useMainStore } from "./mainStore";
import { computedAsync } from "@vueuse/core";
import { getLoginUrlInjectKey } from "@/AppShell.3.vue.InjectKeys";
import { focusTrapSetup } from "@/lib/focusTrapMixin.3";
interface Props {
    callback: () => void;
    mode: string;
    isLoggedIn: boolean;
    wasLoggedIn: boolean;
    isLoggedInNow: () => Promise<boolean>;
}
export default defineComponent({
    props: ["callback", "mode", "isLoggedIn", "wasLoggedIn", "isLoggedInNow"],
    emits: ["close"],
    //eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO - figure out why SetupContext gives overload failure
    setup(props: Props, setupContextUntyped: any) {
        const emit = (setupContextUntyped as SetupContext).emit;
        const containerRef = ref<HTMLElement>(null!);
        focusTrapSetup(containerRef);
        const getLoginUrl = inject(getLoginUrlInjectKey)!;
        const loginUrl = computedAsync(async () => {
            const mainStore = useMainStore();
            const url = await mainStore.getUrl("loginReturnAndClose");
            return await getLoginUrl(props.mode, url); //
        });
        //const homeUrl = computedAsync(async () => {
        //    const mainStore = useMainStore();
        //    return await mainStore.getUrl("index");
        //});

        const login = async () => {
            const loginWindow = window.open(loginUrl.value);
            await waitForWindow(loginWindow!);
            if (await props.isLoggedInNow()) {
                emit("close");
                props.callback();
            } else {
                debugger;
            }
        };

        const waitForWindow = (winObj: Window) => {
            return new Promise((resolve, reject) => {
                const f = () => {
                    try {
                        if (winObj.closed) {
                            resolve(null);
                        } else {
                            setTimeout(f, 100);
                        }
                    } catch (e) {
                        reject(e);
                    }
                };
                f();
            });
        };

        return {
            container: containerRef,
            login,
        };
    },
});
</script>

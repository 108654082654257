import { ref, onMounted, onUnmounted, Ref, nextTick } from "vue";
import { useMainStore } from "@/mainStore";

// export const focusTrapProps = ["focusTrapContainer"];
// export const focusTrapPropsObject = { focusTrapContainer: [HTMLElement, String] };
// export type FocusTrapProps = {
//     focusTrapContainer: HTMLElement | string;
// };

const focusQuery = ["input", "select", "textarea", "button", "object", "a[href]", "area[href]", "[tabindex]"].map(i => i + ':not([disabled]):not([tabindex="-1"])').join(", ");
export function focusTrapSetup(focusTrapContainer: Ref<HTMLElement>) {
    const mainStore = useMainStore();
    const focusTrapEnabled = ref(true);

    const focusTrapSetEnabled = (input: boolean) => {
        focusTrapEnabled.value = input;
    };
    const focusTrapListener = (event: FocusEvent) => {
        /**
         * Check if active element is the dialog itself.
         * This means user is clicking to non-focusable elements, for example, text nodes and there's no need to redirect focus.
         * Fix for text selection not persisting due to focus being redirected.
         */
        const dialogFocused = document.activeElement == focusTrapContainer.value.closest("[role='dialog']");
        if (!focusTrapEnabled.value || dialogFocused) {
            return;
        }
        const parents = [];
        let currentEl = event.target! as HTMLElement;
        while (currentEl.parentElement != null) {
            parents.push(currentEl.parentElement);
            currentEl = currentEl.parentElement;
            if (currentEl == focusTrapContainer.value) {
                // Abort if focused element within trap
                return;
            }
        }
        const focusableElements = focusTrapContainer.value.querySelectorAll(focusQuery) as unknown as HTMLInputElement[];
        if (focusableElements.length == 0) {
            return;
        }
        let focusFirstElement = null;
        if (event.target == document.querySelector(focusQuery)) {
            // first element in HTML - focus likely came from outside HTML
            focusFirstElement = true;
        } else {
            currentEl = focusTrapContainer.value;
            while (currentEl.parentElement != null) {
                const parentIndex = parents.indexOf(currentEl.parentElement);
                if (parentIndex >= 0) {
                    if (parentIndex == parents.length - 1) {
                        focusFirstElement = false;
                    } else {
                        const sibs = [...parents[parentIndex].children];
                        const targetIndex = sibs.indexOf(parents[parentIndex + 1]);
                        const thisIndex = sibs.indexOf(currentEl);
                        focusFirstElement = thisIndex < targetIndex;
                    }
                    break;
                }
                currentEl = currentEl.parentElement;
            }
        }
        const focusElement = focusableElements[focusFirstElement ? 0 : focusableElements.length - 1];
        focusTrapEnabled.value = false;
        focusElement.focus();
        focusTrapEnabled.value = true;
    };

    onMounted(async () => {
        document.addEventListener("focusin", focusTrapListener);
        const focusableElements = focusTrapContainer.value.querySelectorAll(focusQuery) as unknown as HTMLInputElement[];
        if (focusableElements.length > 0) {
            await nextTick();
            focusableElements[0].focus();
        }
    });
    onUnmounted(() => {
        document.removeEventListener("focusin", focusTrapListener);
        mainStore.removeLastActiveModal();
        mainStore.toggleFocusTrapActiveModals(true);
    });

    mainStore.toggleFocusTrapActiveModals(false);
    mainStore.addActiveModal(focusTrapEnabled);

    return {
        focusTrapSetEnabled,
    };
}

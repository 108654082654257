<template>
    <div class="notificationBarTop" v-show="showSlidedown" style="display: none" :aria-hidden="!showSlidedown" role="alert">
        You are not running the latest version of this service. To prevent issues, please save your current action and <button type="button" class="btn align-baseline" @click.prevent="refresh">refresh the page</button>.

        <button type="button" class="close icon icon-close" @click.prevent="showSlidedown = false"><span class="sr-only">Close</span></button>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
export default defineComponent({
    setup() {
        const showSlidedown = ref(true);

        const refresh = () => {
            window.location.reload();
        };

        return {
            showSlidedown,
            refresh,
        };
    },
});
</script>

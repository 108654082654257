<template>
    <picture ref="container" v-bind="$attrs" :style="{ width: `${width}px` }">
        <source type="image/svg+xml" :srcset="svgImage" />
        <img :src="gifImage" alt="loading" class="loading" style="height: 100%" :height="width" :width="width" v-bind="$attrs" />
    </picture>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
//import Spinner16Svg from '../images/Spinner-1s-16px.svg';
import Spinner64Svg from "../images/Spinner-1s-64px.svg";
import Spinner200Svg from "../images/Spinner-1s-200px.svg";
import Spinner64SvgLight from "../images/Spinner-1s-64px-light.svg";
import Spinner200SvgLight from "../images/Spinner-1s-200px-light.svg";
//import Spinner16Gif from '../images/Spinner-1s-16px.gif';
import Spinner64Gif from "../images/Spinner-1s-64px.gif";
import Spinner200Gif from "../images/Spinner-1s-200px.gif";
import Spinner64GifLight from "../images/Spinner-1s-64px-light.gif";
import Spinner200GifLight from "../images/Spinner-1s-200px-light.gif";
type Props = { width: number; imageWidth: number };
export default defineComponent({
    inheritAttrs: false,
    props: ["width", "imageWidth"],
    setup(props: Props) {
        const containerRef = ref<HTMLElement>(null!);
        const color = ref("light");

        const effectiveWidth = computed(() => {
            return props.imageWidth != null ? props.imageWidth : props.width;
        });
        const svgImage = computed(() => {
            /*if (effectiveWidth.value <= 16) {
                    return Spinner16Svg;
                } else */ if (effectiveWidth.value <= 64) {
                return color.value == "light" ? Spinner64Svg : Spinner64SvgLight;
            } else {
                return color.value == "light" ? Spinner200Svg : Spinner200SvgLight;
            }
        });
        const gifImage = computed(() => {
            /*if (effectiveWidth.value <= 16) {
                    return Spinner16Gif;
                } else */ if (effectiveWidth.value <= 64) {
                return color.value == "light" ? Spinner64Gif : Spinner64GifLight;
            } else {
                return color.value == "light" ? Spinner200Gif : Spinner200GifLight;
            }
        });

        onMounted(() => {
            // https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
            const componentLumen = (i: number) => (i < 10 ? i / 3294 : Math.pow(i / 269 + 0.0513, 2.4));
            const lumen = (r: number, g: number, b: number) => 0.2126 * componentLumen(r) + 0.7152 * componentLumen(g) + 0.0722 * componentLumen(b);
            const whiteLumen = lumen(255, 255, 255);
            const blackLumen = lumen(0, 0, 0);
            let node: typeof containerRef.value | null = containerRef.value;
            let [red, green, blue, alpha] = [0, 0, 0, 0];
            while (alpha < 1 && node != null) {
                const bgColor = window.getComputedStyle(node).backgroundColor ?? "";
                const match = bgColor.match(/^rgba?\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:,\s*((?:.|\d)+))?\s*\)$/i);
                if (match != null && match.length >= 4) {
                    let [_, thisRed, thisGreen, thisBlue, thisAlpha] = match.map(i => +i);
                    thisAlpha = thisAlpha == null || isNaN(thisAlpha) ? 1 : thisAlpha;
                    const mixAlpha = 1 - (1 - thisAlpha) * (1 - alpha);
                    red = mixAlpha == 0 ? 0 : Math.round((thisRed * thisAlpha) / mixAlpha + (red * (1 - thisAlpha)) / mixAlpha);
                    blue = mixAlpha == 0 ? 0 : Math.round((thisBlue * thisAlpha) / mixAlpha + (blue * (1 - thisAlpha)) / mixAlpha);
                    green = mixAlpha == 0 ? 0 : Math.round((thisGreen * thisAlpha) / mixAlpha + (green * (1 - thisAlpha)) / mixAlpha);
                    alpha = mixAlpha;
                }
                node = node.parentElement;
            }
            const bgLumen = lumen(red * alpha, green * alpha, blue * alpha);
            //https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
            const contrast = (a: number, b: number) => (Math.max(a, b) + 0.05) / (Math.min(a, b) + 0.05);
            const whiteContrast = contrast(bgLumen, whiteLumen);
            const blackContrast = contrast(bgLumen, blackLumen);
            if (whiteContrast > blackContrast) {
                color.value = "dark";
            } else {
                color.value = "light";
            }
        });

        return {
            container: containerRef,
            gifImage,
            svgImage,
        };
    },
});
</script>

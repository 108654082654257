<template>
    <div v-bind="$attrs">
        <LoadingWheel :width="100" style="margin: auto" />
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoadingWheel from "./LoadingWheel.3.vue";
export default defineComponent({
    components: {
        LoadingWheel,
    },
});
</script>

<template>
    <div class="modal-content" ref="container">
        <div class="modal-header">
            <h2 class="modal-title">{{ status == "deleted" ? "Form Deleted" : "Confirm Delete" }}</h2>
            <button type="button" class="close icon icon-close" id="topClose" aria-hidden="true" @click="close" autofocus :disabled="status == 'deleting'" aria-label="Close" />
        </div>
        <div class="modal-body">
            <p v-if="status == 'pending' && !asAdmin" describedby-text>Are you sure you want to permanently delete this form?</p>
            <p v-if="status == 'pending' && asAdmin" describedby-text>Are you sure you want to delete this submission?</p>
            <div v-else-if="status == 'deleting'">
                <LoadingWheel :width="100" />
            </div>
            <template v-else-if="status == 'deleted'">
                <span class="action-success-icon icon icon-check"></span>
                <p describedby-text class="action-success-text">Form deleted successfully.</p>
            </template>
        </div>
        <div class="modal-footer">
            <a class="btn btn-outline-secondary" @click.prevent="close" id="bottomClose" v-if="status == 'deleted'">Close</a>
            <template v-else>
                <button class="btn btn-primary" @click="submit" :disabled="status == 'deleting'" id="deleteBtn" v-if="!asAdmin">Delete Form</button>
                <button class="btn btn-danger" @click="submit" :disabled="status == 'deleting'" id="deleteBtn" v-if="asAdmin">Delete</button>
                <button class="btn btn-outline-secondary" @click="close" id="bottomClose" :disabled="status == 'deleting'">Cancel</button>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, SetupContext } from "vue";
import LoadingWheel from "@/lib/LoadingWheel.3.vue";
import { dialogSetup, DialogProps } from "@/lib/dialogMixin.3";
type Props = {
    asAdmin: boolean;
    deleteFunction: () => Promise<void>;
} & DialogProps;
export default defineComponent({
    props: ["deleteFunction", "asAdmin", "triggerElement"],
    setup(props: Props, context: SetupContext) {
        const containerRef = ref<HTMLElement>(null!);
        dialogSetup(containerRef, props);
        const status = ref("pending" as "pending" | "deleting" | "deleted");

        const submit = async () => {
            status.value = "deleting";
            await props.deleteFunction();
            status.value = "deleted";
        };

        return {
            container: containerRef,
            submit,
            status,
            close: () => context.emit("close"),
        };
    },
    components: {
        LoadingWheel,
    },
});
</script>

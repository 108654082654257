import { defineStore } from "pinia"; //cspell:ignore pinia // todo global
import distinct from "./lib/distinct";
import resolvablePromise, { ResolvablePromise } from "./lib/resolvablePromise";
import { LoginJson } from "@cs/LoginJson";
import { GlobalConfigData } from "@cs/GlobalConfigData";
import { UserData } from "@cs/UserData";
import type { Pinia } from "pinia";
import { flatObjectOfFunc } from "@/lib/piniaHelper";
import { Ref } from "vue";

export interface TenantData {
    customFooterHtml: string;
    customErrorHtml: string;
    customNotFoundHtml: string;
    customSessionExpiredHtml: string;
    tenantCode: string;
    tenantUrlSlug: string;
    logoUrl: string;
    logoAlt: string;
    timeZone: string;
    externalHomePageUrl: string;
    tenantDisplayLabel: string;
    publicAreaEnabled: boolean;
    adminAreaEnabled: boolean;
    googleAnalyticsIds: string[];
    urlTemplates: {
        tenanted: Record<string, Record<string, string>>;
        defaultTenant: Record<string, Record<string, string>>;
    };
}
export interface LiveRegionMessage {
    success: string;
    failure: string;
    pending: string;
}

function _getTenantLookup(codes: string[], urlPromise: () => Promise<string>, idKey: string, cache: { [key: string]: Promise<string> }, refreshCache: boolean | undefined): { [key: string]: Promise<string> } {
    codes = distinct(codes);
    const missingCodes = refreshCache ? codes : codes.filter(i => !cache[i]);
    if (missingCodes.length > 0) {
        let index = 0;
        while (missingCodes.length > index) {
            let urlParams = "";
            const startIndex = index;
            while (missingCodes.length > index) {
                const urlParam = (urlParams == "" ? "?" : "&") + idKey + "=" + missingCodes[index];
                if (urlParam.length + urlParams.length > 6000) {
                    break;
                } else {
                    urlParams += urlParam;
                    index++;
                }
            }
            const fetchData = urlPromise()
                .then(baseUrl => fetch(baseUrl + urlParams, { cache: refreshCache ? "reload" : "default" }))
                .then(i => i.json());
            missingCodes.slice(startIndex, index).forEach(code => {
                //if (code in cache) {
                //    throw new Error("A:"+JSON.stringify(code));
                //}
                cache[code] = fetchData.then(i => i[code.toLowerCase()]);
            });
        }
        //const a = missingCodes.filter(i => !(i in cache));
        //if (a.length > 0) {
        //    throw new Error("B:"+JSON.stringify(a));
        //}
    }
    const result = {} as Record<string, Promise<string>>;
    codes.forEach(code => {
        result[code] = cache[code];
    });
    return result;
}
function _getTenantDisplayLabel(state: storeState, codes: string[], refreshCache?: boolean | undefined): { [key: string]: Promise<string> } {
    return _getTenantLookup(codes, () => state.urlFetcher("getTenantDisplayLabel"), "tenantCodes", state.tenantDisplayLabelsCache, refreshCache);
}
function _getTenantUrlSlug(state: storeState, codes: string[], refreshCache?: boolean | undefined): { [key: string]: Promise<string> } {
    return _getTenantLookup(codes, () => state.urlFetcher("getTenantUrlSlugs"), "tenantCodes", state.tenantUrlSlugCache, refreshCache);
}
function _getTenantCodeByUrlSlug(state: storeState, urlSlugs: string[], refreshCache?: boolean | undefined): { [key: string]: Promise<string> } {
    return _getTenantLookup(urlSlugs, () => state.urlFetcher("getTenantCodeByUrlSlugs"), "urlSlugs", state.tenantCodeByUrlSlugCache, refreshCache);
}
function _getTenantDisplayLabelByUrlSlug(state: storeState, urlSlugs: string[], refreshCache?: boolean | undefined): { [key: string]: Promise<string> } {
    return _getTenantLookup(urlSlugs, () => state.urlFetcher("getTenantDisplayLabelByUrlSlugs"), "urlSlugs", state.tenantDisplayLabelByUrlSlugCache, refreshCache);
}
type storeState = ReturnType<typeof stateFunc>;
const stateFunc = () => ({
    activeFeatureFlagsPromise: resolvablePromise<string[]>(),
    xrsfToken: null as null | string,
    urlFetcher: null! as (key: string, data?: { [key: string]: string | number | boolean | null | undefined }) => Promise<string>,
    globalConfigData: null! as GlobalConfigData,
    userDataPromise: resolvablePromise<UserData>(),
    tenantData: null! as TenantData,
    tenantDisplayLabelsCache: {} as { [key: string]: Promise<string> },
    tenantUrlSlugCache: {} as { [key: string]: Promise<string> },
    tenantCodeByUrlSlugCache: {} as { [key: string]: Promise<string> },
    tenantDisplayLabelByUrlSlugCache: {} as { [key: string]: Promise<string> },
    loginSessionExpire: null as null | Date,
    hasVersionMismatch: false,
    hasSession: false,
    wantedVersionHash: null as null | string,
    needsLogin: false,
    needsLoginMode: null as null | LoginJson,
    needsLoginPromise: null as null | ResolvablePromise<void>,
    shouldEnsureSiteCssLoaded: false,
    siteCssLoaded: resolvablePromise<void>(),
    activeModals: [] as Ref<boolean>[],
    liveRegionPromise: null as Promise<string> | null | undefined,
    liveRegionPendingMessage: undefined as string | undefined,
});
const getters = {
    tenantCode: (state: storeState) => (state.tenantData || {}).tenantCode as string,
    getTenantDisplayLabel: (state: storeState) => (codes: string[], refreshCache?: boolean | undefined) => _getTenantDisplayLabel(state, codes, refreshCache),
    getTenantUrlSlug: (state: storeState) => (codes: string[], refreshCache?: boolean | undefined) => _getTenantUrlSlug(state, codes, refreshCache),
    getTenantCodeByUrlSlug: (state: storeState) => (urlSlugs: string[], refreshCache?: boolean | undefined) => _getTenantCodeByUrlSlug(state, urlSlugs, refreshCache),
    getTenantDisplayLabelByUrlSlug: (state: storeState) => (urlSlugs: string[], refreshCache?: boolean | undefined) => _getTenantDisplayLabelByUrlSlug(state, urlSlugs, refreshCache),
    getTimeZone: (state: storeState) => (code: string[] | string | null | undefined) => (state.tenantData || {}).timeZone as string, // TODO per container
    isFeatureFlagActiveAsync: (state: storeState) => async (flag: string) => (await state.activeFeatureFlagsPromise).includes(flag),
    getUrl: (state: storeState) => (key: string, data?: { [key: string]: string | number | boolean | null | undefined }) => state.urlFetcher(key, data),
    isLoggedInPromise: async (state: storeState) => (await state.userDataPromise)?.IsLoggedIn,
    now: (state: storeState) => () => {
        const nowStr = state.globalConfigData?.OverriddenNow as unknown as string | null;
        let now = nowStr == null || nowStr == "" ? null : new Date(nowStr);
        const autoAdvance = state.globalConfigData?.OverriddenNowAutoAdvance ?? true;
        if (now == null) {
            now = new Date();
        } else if (autoAdvance) {
            const autoAdvanceFromStr = state.globalConfigData?.OverriddenNowOn as unknown as string | null;
            const autoAdvanceFrom = autoAdvanceFromStr == null || autoAdvanceFromStr == "" ? null : new Date(autoAdvanceFromStr);
            const diff = Date.now() - autoAdvanceFrom!.getTime();
            now = new Date(now!.getTime() + diff);
        }
        return now;
    },
};
const actions = {
    setNeedsLogin(this: storeState, mode: LoginJson) {
        this.needsLoginPromise = resolvablePromise();
        this.needsLogin = true;
        this.needsLoginMode = mode;
        this.needsLoginPromise.then(() => {
            this.needsLogin = false;
        });
    },
    setVersionMismatch(this: storeState, wantedVersionHash: string) {
        this.hasVersionMismatch = true;
        this.wantedVersionHash = wantedVersionHash;
    },
    setXrsfToken(this: storeState, xrsfToken: string) {
        this.xrsfToken = xrsfToken;
    },
    async ensureSiteCssLoaded(this: storeState) {
        this.shouldEnsureSiteCssLoaded = true;
        return this.siteCssLoaded;
    },
    setUserData(this: storeState, userData: UserData) {
        this.userDataPromise = resolvablePromise<UserData>();
        this.userDataPromise.resolve(userData);
    },
    addActiveModal(this: storeState, newModal: Ref<boolean>) {
        this.activeModals.push(newModal);
    },
    removeLastActiveModal(this: storeState) {
        this.activeModals.pop();
    },
    toggleFocusTrapActiveModals(this: storeState, focusTrapState: boolean) {
        const activeModals = this.activeModals;
        if (!activeModals.length) return;
        if (focusTrapState) {
            activeModals[activeModals.length - 1].value = focusTrapState;
        } else {
            for (const activeModal of activeModals) {
                activeModal.value = focusTrapState;
            }
        }
    },
    setLiveRegion(this: storeState, promise: null | Promise<string>, pendingMessage?: string) {
        this.liveRegionPendingMessage = pendingMessage;
        this.liveRegionPromise = promise;
    },
};
export const useMainStore = defineStore<string, storeState, typeof getters, typeof actions>("main", {
    state: stateFunc,
    getters,
    actions,
}) as (pinia?: Pinia | null | undefined) => mainStore;
export type mainStore = storeState & flatObjectOfFunc<typeof getters> & typeof actions;

export default <T>(array: T[]) => {
    const set = new Set<T>();
    array.forEach(v => set.add(v));
    const result: T[] = [];
    set.forEach(v => result.push(v));
    return result;
};
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectDistinct<T>(array: T[], map?: (i: T) => any) {
    const result = [];
    const obj: Record<string, 1> = {};
    for (const item of array) {
        const json = JSON.stringify(map == null ? item : map(item));
        if (!(json in obj)) {
            obj[json] = 1;
            result.push(item);
        }
    }
    return result;
}

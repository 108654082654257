//window.onerror = (a,b,c) => {
//    debugger;
//}
import "whatwg-fetch";
import "./config";
import "./polyfill";
import "skipto/downloads/js/skipto.min.js";
import "./publicPath";
import { createApp } from "vue";
import { createPinia } from "pinia"; //cspell:ignore pinia // todo global
import { useMainStore, TenantData } from "./mainStore";
import { GlobalConfigData } from "@cs/GlobalConfigData";
import { vue3Debounce } from "vue-debounce";
import AppShell from "./AppShell.3.vue";
import { vfmPlugin } from "vue-final-modal";
import { vfmInjectKey } from "./appExportKeys.3";

const tenantDataString = document.getElementById("tenantData")?.innerHTML;
let tenantData: TenantData;
if (tenantDataString == "/*644f65f6-b806-49fe-927a-000a3a40d73e*/" || tenantDataString == null) {
    tenantData = {} as TenantData; // TODO
} else {
    tenantData = JSON.parse(tenantDataString);
}

const globalConfigDataString = document.getElementById("globalConfigData")?.innerHTML;
let globalConfigData: GlobalConfigData;
if (globalConfigDataString == "/*d599d548-2a76-4900-805c-c00672ca77af*/" || globalConfigDataString == null) {
    globalConfigData = {} as GlobalConfigData; // TODO
} else {
    globalConfigData = JSON.parse(globalConfigDataString);
}

Array.from(document.getElementsByTagName("link"))
    .filter(i => i.dataset.rel != null)
    .forEach(i => {
        i.rel = i.dataset.rel!;
    });

const pinia = createPinia();
const app = createApp({
    ...AppShell,
});
//@ts-ignore
app.use(pinia);
app.use(
    vfmPlugin({
        key: vfmInjectKey as unknown as string,
    }),
);
app.directive("debounce", vue3Debounce({ trim: true }));

const mainStore = useMainStore();
mainStore.tenantData = tenantData;
mainStore.globalConfigData = globalConfigData;
app.mount("#container");

export default app;

////@ts-ignore
//if (module.hot) {
//    // accept actions and mutations as hot modules
//    //@ts-ignore
//    module.hot.accept(["./pages/pageState/module.js"], () => {
//        //@ts-ignore
//        const newPageModule = require("./pages/pageState/module.js").default; // eslint-disable-line @typescript-eslint/no-var-requires
//
//        // swap in the new modules and mutations
//        store.hotUpdate({
//            modules: {
//                page: newPageModule,
//            },
//        });
//    });
//}

//if ("serviceWorker" in navigator) {
//    window.addEventListener("load", () => {
//        navigator.serviceWorker.register(tenantData.urlTemplates.defaultTenant.serviceWorker);
//    });
//}

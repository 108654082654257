import moment from "moment";
import "moment-timezone";
export function convertTimeZone(datetime: Date | string, oldTimeZone: string, newTimeZone: string, format: string) {
    const zonedDateTime = moment.tz(datetime, oldTimeZone);
    zonedDateTime.tz(newTimeZone);
    return zonedDateTime.format(format);
}
export function convertUtcToTimeZone(datetime: Date | string, newTimeZone: string, format: string) {
    return convertTimeZone(datetime, "Etc/UTC", newTimeZone, format);
}
export function convertTimeZoneToUtc(datetime: Date | string, newTimeZone: string, format: string) {
    return convertTimeZone(datetime, newTimeZone, "Etc/UTC", format);
}

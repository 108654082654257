<style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin: 0 1rem; */
    padding: 0.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    /*transform: scale(0.95);*/
    width: min(90vw, 600px);
}
/*
::v-deep(.modal-header) {
    font-size: 1.5rem;
    font-weight: 700;
}
*/
.dark-mode div::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>
<template>
    <vue-final-modal v-slot="{ close, params }" :focus-retain="false" z-index="1002" v-bind="$attrs" classes="modal-container">
        <component :is="params.innerComponent" v-bind="params.innerParams" @close="close"></component>
    </vue-final-modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
    props: ["innerComponent", "innerParams"],
    inheritAttrs: false,
});
</script>

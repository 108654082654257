<template>
    <div class="sr-only" role="status" aria-live="polite">
        {{ currentMessage }}
    </div>
</template>
<script lang="ts">
import { defineComponent, computed, watch, ref } from "vue";
import { useMainStore } from "../mainStore";
import { Subject, Observable } from "rxjs";
import { switchAll } from "rxjs/operators";
import delayPromise from "@/lib/delayPromise";
let isResetSkipped = false;
export default defineComponent({
    skipReset() {
        isResetSkipped = true;
    },
    setup() {
        const message$ = new Subject<Observable<string>>();
        const defaultMessage = { success: "Success", failure: "Failed", pending: "Loading" };
        const mainStore = useMainStore();
        const currentMessage = ref<string | null>(null);
        const promise = computed(() => mainStore.liveRegionPromise);

        watch(promise, promiseVal => {
            message$.next(
                new Observable(subscriber => {
                    (async () => {
                        subscriber.next(mainStore.liveRegionPendingMessage ?? defaultMessage.pending);
                        try {
                            subscriber.next((await promiseVal) ?? defaultMessage.success);
                        } catch (e) {
                            subscriber.next((e as string) ?? defaultMessage.failure);
                        }
                        if (!isResetSkipped) {
                            await delayPromise(200);
                            subscriber.next("");
                        }
                        subscriber.complete();
                    })();
                }),
            );
        });

        message$.pipe(switchAll()).subscribe(message => {
            currentMessage.value = message;
        });
        const exposed = { currentMessage };

        ("#if JEST");
        //@ts-ignore
        exposed.promise = promise;
        ("#endif");

        return exposed;
    },
});
</script>
